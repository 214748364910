import * as React from 'react'
import NavHeader from '../components/navHeader'
import SiteFooter from '../components/footer'
import PageTitle from '../components/pageTitle'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import * as newsStyle from '../styles/news.module.scss'

const News = ({ data }) => {
  const ExtractDataNews = data.allMdx.nodes
  return (

    <main>
    <NavHeader/>
    <PageTitle pageTitle="Správy"/>
      <div className="mainContainer">
       
          {
          ExtractDataNews.map(node=> (
            <section>
            <div className="sectionContainer" key={node.id}>
            <h2>{node.frontmatter.title}</h2>
            <p>{node.frontmatter.description}</p>
            <Link to={"/" + node.slug} className={newsStyle.newsButton}>čítať ďalej</Link>
            <div className="sectionSeparatorContainer">

            <div className="section-ln-sp-item" id={newsStyle.sectionItemNews}></div>

            </div>
          </div>
        </section>
        ))
        }
      </div>
    <SiteFooter/>
    </main>

  )
}



export const query = graphql`
{
  allMdx(
    sort: {fields: frontmatter___date, order: DESC}
    filter: {frontmatter: {categories: {eq: "sk"}}}
  ) {
    nodes {
      frontmatter {
        title
        description
        categories
        date
      }
      id
      slug
    }
  }
}
`

export default News
